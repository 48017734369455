var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{attrs:{"justify":"end"}},[_c('v-col',[_c('h2',[_vm._v("ສະຫຼຸບຍອດຂາຍໃຫ້ສາຂາຕົວແທນ")])]),_c('v-col',{staticClass:"al-end"},[_c('v-btn',{attrs:{"color":"primary","loading":_vm.exportLoading,"disabled":_vm.exportLoading},on:{"click":_vm.exportData}},[_vm._v("Export ")])],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"ເລີ່ມວັນທີ","readonly":"","outlined":"","dense":""},model:{value:(_vm.start_date),callback:function ($$v) {_vm.start_date=$$v},expression:"start_date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.start_menu),callback:function ($$v) {_vm.start_menu=$$v},expression:"start_menu"}},[_c('v-date-picker',{model:{value:(_vm.start_date),callback:function ($$v) {_vm.start_date=$$v},expression:"start_date"}})],1)],1),_c('v-spacer'),_c('v-col',{attrs:{"cols":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"ຫາວັນທີ","readonly":"","outlined":"","dense":""},model:{value:(_vm.end_date),callback:function ($$v) {_vm.end_date=$$v},expression:"end_date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.end_menu),callback:function ($$v) {_vm.end_menu=$$v},expression:"end_menu"}},[_c('v-date-picker',{model:{value:(_vm.end_date),callback:function ($$v) {_vm.end_date=$$v},expression:"end_date"}})],1)],1),_c('v-col',{staticClass:"d-flex",attrs:{"cols":"3"}},[_c('v-select',{attrs:{"items":_vm.statuses,"label":"ສະຖານະ","item-text":"name","item-value":"value","dense":"","outlined":""},model:{value:(_vm.selectedStatus),callback:function ($$v) {_vm.selectedStatus=$$v},expression:"selectedStatus"}})],1),_c('v-col',{staticClass:"d-flex",attrs:{"cols":"3"}},[_c('v-select',{attrs:{"items":_vm.filteredItems,"label":"ເລືອກສາຂາ","item-text":"name","item-value":"id","dense":"","outlined":""},model:{value:(_vm.selectedBranch),callback:function ($$v) {_vm.selectedBranch=$$v},expression:"selectedBranch"}})],1)],1),_c('v-row',{staticClass:"mb-1"},[_c('v-col',{attrs:{"cols":"3"}},[_c('h4',[_vm._v("ລວມບິນ: "+_vm._s(Intl.NumberFormat().format(_vm.totalBill)))])]),_c('v-col',{attrs:{"cols":"3"}},[_c('h4',[_vm._v("ລວມສາຂາ: "+_vm._s(Intl.NumberFormat().format(_vm.totalBill)))])]),_c('v-col',{attrs:{"cols":"3"}},[_c('h4',[_vm._v("ລວມຕຸກນ້ຳ: "+_vm._s(Intl.NumberFormat().format(_vm.sumTotalBottles)))])]),_c('v-col',{staticClass:"3"},[_c('h4',[_vm._v("ລວມເງິນທັງໝົດ: "+_vm._s(Intl.NumberFormat().format(_vm.sumTotalPrice)))])])],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.branchInvoices,"disable-pagination":true,"hide-default-footer":"","loading":_vm.TableLoading,"disabled":_vm.TableLoading,"loading-text":"Loading... Please wait"},scopedSlots:_vm._u([{key:"item.total",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(Intl.NumberFormat().format(item.total))+" ")]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.moment(item.created_at).format("YYYY-MM-DD hh:mm:ss"))+" ")]}},{key:"item.success_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.moment(item.success_at).format("YYYY-MM-DD"))+" ")]}},{key:"item.total_discount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(Intl.NumberFormat().format(item.total_discount))+" ")]}},{key:"item.subTotal",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(Intl.NumberFormat().format(item.subTotal))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":"success"}},[_vm._v(_vm._s(item.status))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.viewInvoice(item.id)}}},[_vm._v("mdi-eye ")])]}}])}),_c('br'),[(_vm.pagination.last_page > 1)?_c('Pagination',{attrs:{"pagination":_vm.pagination,"offset":_vm.offset},on:{"paginate":function($event){return _vm.fetchBranchInvoice()}}}):_vm._e()]],2)}
var staticRenderFns = []

export { render, staticRenderFns }